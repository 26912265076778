import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GallerySection from "../../components/composites/GallerySection";
import { PageContent } from "../../types/common";

const Gallery = () => {
  const [galleryPageSize, setGalleryPageSize] = React.useState<number>(100);
  const [galleryRecord, setGalleryRecord] = useState<any>([]);
  const [moreData, setMoreData] = useState<boolean>(true);
  const [galleryMeta, setGalleryMeta] = useState<any>();
  const [page, setPage] = useState(1);
  const notify = (message: string) => toast.error(message, {
    position: toast.POSITION.BOTTOM_LEFT
  });

  async function fetchGalleryData(page: number) {
    const msg = `An error while retrieving services content!`;
    try {
      const params = {
        "populate": "media,media.file",
        "pagination[pageSize]": galleryPageSize,
        "pagination[page]": page,
        "sort": "id:desc"
      };
      const { status, data } = await api("/api/gallery-images", "get", params);
      if (status >= 200 && status <= 299) {
        const newPage = page + 1;
        const newList = galleryRecord.concat(data?.data);
        setGalleryMeta(data?.meta);
        setGalleryRecord(newList);
        setPage(newPage);
        if (data?.meta?.pagination?.total === newList.length) {
          setMoreData(false);
        }
      } else {
        notify(msg);
      }
    } catch (err) {
      console.log(err);
      notify(msg);
    }
  }

  useEffect(() => {
    fetchGalleryData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <GallerySection galleryRecord={galleryRecord} moreData={moreData} galleryMeta={galleryMeta?.pagination} content={PageContent.Gallery} page={page} fetchGalleryData={fetchGalleryData} />

  );
};

export default Gallery;